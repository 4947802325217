package io.telereso.kmp.starter

import org.jetbrains.compose.web.css.Style
import org.jetbrains.compose.web.renderComposable
import io.telereso.kmp.starter.components.Layout
import io.telereso.kmp.starter.components.MainContentLayout
import io.telereso.kmp.starter.content.Header
import io.telereso.kmp.starter.content.Intro
import io.telereso.kmp.starter.style.AppStylesheet
val packageJson = require("../package.json")
external fun require(module: String): dynamic

fun main() {
    console.log("Version: ${packageJson.version}")
    renderComposable(rootElementId = "root") {
        Style(AppStylesheet)

        Layout {
            Header()
            MainContentLayout {
                Intro()
            }
        }
    }
}