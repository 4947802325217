package io.telereso.kmp.starter.content

import androidx.compose.runtime.*
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import io.telereso.kmp.starter.components.ContainerInSection
import io.telereso.kmp.starter.services.GenerateProjectService
import io.telereso.kmp.starter.style.*
import kotlinx.browser.document
import org.jetbrains.compose.web.attributes.*

@Composable
fun Intro() {
    ContainerInSection {
        Div({
            classes(WtRows.wtRow, WtRows.wtRowSizeM, WtRows.wtRowSmAlignItemsCenter)
        }) {

            Div({
                classes(WtCols.wtCol2, WtCols.wtColMd3)
                style {
                    alignSelf(AlignSelf.Start)
                }
            }) {
                Img(src = "static/logo.svg", attrs = { classes(AppStylesheet.composeLogo) })
            }

            Div({
                classes(
                    WtCols.wtCol10,
                    WtCols.wtColMd8,
                    WtCols.wtColSm12,
                    WtOffsets.wtTopOffsetSm12
                )
            }) {
                H1(attrs = { classes(WtTexts.wtHero) }) {
                    Text("Kotlin Multiplatform Starter")
                }
                Div({
                    classes(WtDisplay.wtDisplayMdNone)
                }) {
                    IntroSection()
                }
            }
        }


        Div(attrs = {
            classes(WtDisplay.wtDisplayNone, WtDisplay.wtDisplayMdBlock)
        }) {
            IntroSection()
        }
    }
}

data class ProjectForm(val name: String, val packageName: String, val url: String? = null) {
    companion object {
        val default get() = ProjectForm("example", "com.example")
    }
}

@Composable
private fun IntroSection() {
    val projectForm = remember { mutableStateOf(ProjectForm.default) }

    Div({
        classes(WtRows.wtRow, WtRows.wtRowSizeM)
    }) {

        Div({
            classes(WtCols.wtCol9, WtCols.wtColMd9, WtCols.wtColSm12)
        }) {
            P({ classes(WtTexts.wtSubtitle2, WtOffsets.wtTopOffset24) }) {
                Text("Speed up you project initialization with this 6 platforms starter")
                Br { }
                Text(" Android, iOS, Web, JVM, ReactNative, Flutter (soon)")
            }

            P({
                classes(WtTexts.wtText1, WtOffsets.wtTopOffset24)
            }) {
                Text(
                    "Generated project will have 2 modules, Model and Client (lib), Models are shared in both backend services and front end clients"
                )
                Br { }
                Text(
                    "This will make business logic coherent across clients and microservices (backend)"
                )
            }

            ProjectForm(projectForm)


            if (!projectForm.value.url.isNullOrBlank()) {
                Img(src = "static/loader.svg", attrs = {
                    classes(WtOffsets.wtTopOffset24)
                    style {
                        width(80.px)
                        height(80.px)
                    }
                })
            } else {
                A(
                    attrs = {
                        classes(WtTexts.wtButton, WtOffsets.wtTopOffset24)
                        target(ATarget.Blank)
                        onClick {
                            GenerateProjectService.generate(projectForm, document)
                        }
                    },
                ) {
                    Text("Generate")
                }
            }
            if (projectForm.value.url != null)
                A(
                    attrs = {
                        id("downloadZipFile")
                        hidden()
                        download(projectForm.value.name )
                    },
                    href = projectForm.value.url
                )
        }
    }
}


@Composable
private fun ProjectForm(projectForm: MutableState<ProjectForm>) {
    val loading = !projectForm.value.url.isNullOrBlank()
    Form(attrs = {
        classes(WtOffsets.wtTopOffset24)
    }) {

        P({
            classes(WtTexts.wtText1)
        }) {
            Span({
                style {
                    marginRight(8.px)
                }
            }) { Text("Project Name") }
            Input(InputType.Text) {
                defaultValue(projectForm.value.name)
                onChange {
                    projectForm.value = projectForm.value.copy(name = it.value)
                }
                if (loading)
                    disabled()
            }
        }

        Br {}
        P({
            classes(WtTexts.wtText1)
        }) {
            Span({
                style {
                    marginRight(8.px)
                }
            }) { Text("Package") }
            Input(InputType.Text) {
                defaultValue(projectForm.value.packageName)
                onChange {
                    projectForm.value = projectForm.value.copy(packageName = it.value)
                }
                if (loading)
                    disabled()
            }
        }
    }

}