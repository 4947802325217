package io.telereso.kmp.starter.services

import androidx.compose.runtime.MutableState
import io.ktor.client.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.util.*
import io.ktor.utils.io.*
import io.telereso.kmp.starter.content.ProjectForm
import kotlinx.browser.window
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import org.w3c.dom.Document
import org.w3c.dom.HTMLElement
import org.w3c.dom.url.URL
import org.w3c.files.File

object GenerateProjectService {

    val client = HttpClient {
    }

    fun generate(projectForm: MutableState<ProjectForm>, document: Document) {
        GlobalScope.launch {
            val url =
                "${window.location.protocol}/api/v2/generate?name=${projectForm.value?.name}&package=${projectForm.value?.packageName}"
            projectForm.value = projectForm.value.copy(url = url)
            val response = client.get(url)
            val fileBlob = File(response.bodyAsChannel().toByteArray().toTypedArray(), "abc.zip")
            val href = URL.createObjectURL(fileBlob)
            projectForm.value = projectForm.value.copy(url = href)
            (document.getElementById("downloadZipFile") as HTMLElement).click()
            projectForm.value = projectForm.value.copy(url = null)
            URL.revokeObjectURL(href)
        }
    }
}